var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Card', {
    staticClass: "auth-card animated fadeInUp"
  }, [_c('template', {
    slot: "title"
  }, [_c('div', {
    staticClass: "header-logo"
  })]), _vm._t("default"), _c('center', [_vm._t("footer")], 2)], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }