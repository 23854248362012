<template>
  <AuthCard>
    <Form
      class="classic-form"
      ref="resetPasswordForm"
      :model="resetPasswordForm"
      :rules="resetPasswordRules"
    >
      <div>
        <FormItem
          label="New Password"
          prop="password"
        >
          <Input
            name="password"
            type="password"
            v-model="resetPasswordForm.password"
            placeholder="Enter a new password"
          />
        </FormItem>
        <FormItem
          label="Confirm Password"
          prop="confirmPassword"
        >
          <Input
            type="password"
            v-model="resetPasswordForm.confirmPassword"
            placeholder="Enter a new password"
          />
        </FormItem>
        <FormItem class="form-action-btn">
          <Button
            :loading="isLoading"
            type="primary"
            class="btn--elevated"
            size="large"
            long
            @click="resetPassword"
          >
            {{ this.$t('global.user.change_pass') }}
          </Button>
        </FormItem>
      </div>
      <!--<div v-else-if="!isTokenValid" class="text-center">-->
      <!--The token is expired or invalid.-->
      <!--</div>-->
      <!--<div v-else class="text-center">-->
      <!--Loading...-->
      <!--</div>-->
      <div class="alternate-btn">
        <router-link
          tag="a"
          to="/auth/login"
          class="clickable-el heavy"
        >
          <i class="fa fa-arrow-left" />
          {{ this.$t('global.user.back_login') }}
        </router-link>
      </div>
    </Form>
  </AuthCard>
</template>

<script>
import AuthCard from '@/views/Login/AuthCard'

export default {
  name: 'ResetPassword',
  components: {
    AuthCard
  },
  data () {
    const validatePasswordIsSame = (rule, value, callback) => {
      if (value === this.resetPasswordForm.password) {
        callback()
      } else {
        callback(new Error('Passwords do not match'))
      }

    }
    const validatePasswordFormat = (rule, value, callback) => {
      // password must contain 1 number (0-9)
      // password must contain 1 uppercase letters
      // password must contain 1 lowercase letters
      // password must contain 1 non-alpha numeric number
      // password is 8-16 characters with no space
      const regex = new RegExp(/^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[^\w\d\s:])([^\s]){8,16}$/)
      if (!value.match(regex)) {
        callback(new Error(
          `requires 1 number, 1 uppercase letter, 1 lowercase letter, 1 non-alpha numeric character and be 8-16 chars`
        ))
      } else {
        callback()
      }
      // callback()

    }

    return {
      isLoading: true,
      isTokenValid: false,
      resetPasswordForm: {
        password: '',
        confirmPassword: ''
      },
      resetPasswordRules: {
        password: [
          {validator: validatePasswordFormat,required: true, min: 8, trigger: 'blur'}
        ],
        confirmPassword: [
          {validator: validatePasswordIsSame, required: true, min: 8, trigger: 'blur'}
        ]
      }
    }
  },
  methods: {
    notifyErrorAndRedirect (text) {
      this.$swal({
        text,
        title: 'Error...',
        type: 'error',
        showCloseButton: true,
        showConfirmButton: false
      })
      this.$Notice.open({
        title: this.$t('global.validation.error'),
        desc: this.$t('global.validation.serv_error')
      })
      this.$router.push({path: '/auth/login'})
    },
    async validateToken () {
      this.isLoading = true
      const {token} = this.$route.params
      if (!token) {
        return this.notifyErrorAndRedirect('No token found in the query.')
      }
      try {
        const {data: responseData} = await this.$axios.get(`users/checkResetTokenValidity/${token}`)

        this.isLoading = false
        if (responseData.success) {
          this.isTokenValid = true
        } else {
          return this.notifyErrorAndRedirect('This token is expired or invalid.')
        }
      } catch (e) {
        this.isLoading = false
        console.error(e)
        return this.notifyErrorAndRedirect('This token is expired or invalid.')
      }
    },
    async resetPassword () {
      await this.$refs.resetPasswordForm.validate(async (valid) => {
        if (valid) {
          this.isLoading = true

          try {
            await this.$axios.get('users/resetPassword', {
              params: {
                password: this.resetPasswordForm.password,
                token: this.$route.params.token
              }
            })
            this.isLoading = false
            this.$Notice.open({
              title: this.$t('global.validation.success'),
              desc: this.$t('global.validation.pass_updated')
            })
            this.$router.push({path: '/auth/login'})
          } catch (e) {
            this.isLoading = false
            console.error(e)
            this.$Notice.open({
              title: this.$t('global.validation.error'),
              desc: this.$t('global.validation.serv_error')
            })
            // this.$swal({
            //   title: 'Error...',
            //   text: 'Something went wrong with our server, please try again.',
            //   type: 'error',
            //   showCloseButton: true,
            //   showConfirmButton: false
            // })
          }
        }
      })
    }
  },
  mounted () {
    this.validateToken()
  }
}
</script>

<style lang="scss" scoped>
    .classic-form {
        .ivu-form-item {
            margin-top: 20px;

            &.form-action-btn {
                margin-top: 30px;
            }
        }
    }

    .alternate-btn {
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
</style>
