<template>
  <Card class="auth-card animated fadeInUp">
    <template slot="title">
      <div class="header-logo" />
    </template>
    <slot />
    <center>
      <slot name="footer" />
    </center>
  </Card>
</template>

<script>
export default {
  name: 'AuthCard'
}
</script>

<style lang="scss" scoped>
.auth-card {
  width: 100vw;
  max-width: 400px;
  //padding: 16px;
  // border-radius: 4px;
  //box-shadow: 0 3px 6px hsla(0, 0%, 0%, 0.15), 0 2px 4px hsla(0, 0%, 0%, 0.12);
  //transition: box-shadow 100ms ease-out;
  background: #fff;

  .header-logo {
    background: url('../../assets/images/logo-f7-hor-dark.png') no-repeat center center / 200px 32px;
    //background: url('../../assets/images/login-facettes-b.svg') no-repeat center;
    //background-size: contain;
    //             margin-bottom: 20px;
    height: 32px;
  }
}

.animated {
  //-webkit-animation-duration: .5s;
  //-webkit-animation-fill-mode: both;
  animation-duration: .5s;
  animation-fill-mode: both;
}

//@-webkit-keyframes fadeInUp {
//  from {
//    opacity: 0;
//    -webkit-transform: translate3d(0, 100%, 0);
//    transform: translate3d(0, 100%, 0);
//  }
//
//  to {
//    opacity: 1;
//    -webkit-transform: translate3d(0, 0, 0);
//    transform: translate3d(0, 0, 0);
//  }
//}

@keyframes fadeInUp {
  from {
    opacity: 1;
    //-webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    //-webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}
</style>
