import { render, staticRenderFns } from "./AuthCard.vue?vue&type=template&id=20e3f242&scoped=true"
import script from "./AuthCard.vue?vue&type=script&lang=js"
export * from "./AuthCard.vue?vue&type=script&lang=js"
import style0 from "./AuthCard.vue?vue&type=style&index=0&id=20e3f242&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20e3f242",
  null
  
)

export default component.exports