var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('AuthCard', [_c('Form', {
    ref: "resetPasswordForm",
    staticClass: "classic-form",
    attrs: {
      "model": _vm.resetPasswordForm,
      "rules": _vm.resetPasswordRules
    }
  }, [_c('div', [_c('FormItem', {
    attrs: {
      "label": "New Password",
      "prop": "password"
    }
  }, [_c('Input', {
    attrs: {
      "name": "password",
      "type": "password",
      "placeholder": "Enter a new password"
    },
    model: {
      value: _vm.resetPasswordForm.password,
      callback: function callback($$v) {
        _vm.$set(_vm.resetPasswordForm, "password", $$v);
      },
      expression: "resetPasswordForm.password"
    }
  })], 1), _c('FormItem', {
    attrs: {
      "label": "Confirm Password",
      "prop": "confirmPassword"
    }
  }, [_c('Input', {
    attrs: {
      "type": "password",
      "placeholder": "Enter a new password"
    },
    model: {
      value: _vm.resetPasswordForm.confirmPassword,
      callback: function callback($$v) {
        _vm.$set(_vm.resetPasswordForm, "confirmPassword", $$v);
      },
      expression: "resetPasswordForm.confirmPassword"
    }
  })], 1), _c('FormItem', {
    staticClass: "form-action-btn"
  }, [_c('Button', {
    staticClass: "btn--elevated",
    attrs: {
      "loading": _vm.isLoading,
      "type": "primary",
      "size": "large",
      "long": ""
    },
    on: {
      "click": _vm.resetPassword
    }
  }, [_vm._v(" " + _vm._s(this.$t('global.user.change_pass')) + " ")])], 1)], 1), _c('div', {
    staticClass: "alternate-btn"
  }, [_c('router-link', {
    staticClass: "clickable-el heavy",
    attrs: {
      "tag": "a",
      "to": "/auth/login"
    }
  }, [_c('i', {
    staticClass: "fa fa-arrow-left"
  }), _vm._v(" " + _vm._s(this.$t('global.user.back_login')) + " ")])], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }